import React from 'react'
import styled from '@emotion/styled'

const BoldParagraph = styled.p`
  font-size: 2.4rem;
  line-height: 2.8rem;
  letter-spacing: 1.5px;
  font-weight: 800;
`
const H1 = styled.h1`
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 1rem;
  font-weight: 900;
  margin-bottom: 2rem;
  color: #202123;
`

const H2 = styled.h2`
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  font-weight: 900;
  line-height: 1.2;
  margin: 0;
  
  @media (min-width: 768px) {
    font-size: 2.6rem;
    letter-spacing: 0.75rem;
    margin: .83em 0; 
  }
`

const H3 = styled.h3`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  font-weight: 900;
  line-height: 1.2;
  margin: 0;
  
  @media (min-width: 768px) {
    font-size: 1.8rem;
    letter-spacing: 0.75rem;
    margin: .83em 0; 
  }
`

const HoverLink = styled.a`
  opacity: 0.75;
  transition: opacity 400ms ease-in;
  :hover {
    opacity: 1;
  }
`

export {
  BoldParagraph,
  H1,
  H2,
  H3,
  HoverLink
}
